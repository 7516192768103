.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 0 40px;
  background: linear-gradient(
    105.26deg,
    rgba(255, 255, 255, 0.2) 26.43%,
    rgba(255, 255, 255, 0) 68.93%
  );
  backdrop-filter: blur(15px);

  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;

  ._hover {
    // content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.4s ease;
    opacity: 0;
    background: linear-gradient(105.26deg, #ffc70033 0%, #ffffff00 100%);
    backdrop-filter: blur(15px);

    span {
      &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        transition: all 0.4s ease;
        opacity: 0;
      }

      &.top {
        background-color: #fff500;
        height: 1px;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
      }
      &.left {
        background: linear-gradient(#fff500 0%, #ff8a00 100%);
        height: 100%;
        width: 6px;
        bottom: 0;
        top: 0;
        left: 0;
      }
      &.right {
        background: linear-gradient(#fff500 0%, #ff8a00 100%);
        height: 100%;
        width: 1px;
        bottom: 0;
        top: 0;
        right: 0;
      }
      &.bottom {
        background-color: #ff8a00;
        height: 1px;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  span {
    position: absolute;
    display: block;
    transition: all 0.4s ease;

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      transition: all 0.4s ease;
      opacity: 0;
    }

    &.top {
      background-color: #ff00e5;
      height: 1px;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;

      // &::before {
      //   height: 1px;
      //   width: 100%;
      //   background-color: #fff500;
      // }
    }
    &.left {
      background: linear-gradient(#ff00e5 0%, #8f00ff 100%);
      height: 100%;
      width: 6px;
      bottom: 0;
      top: 0;
      left: 0;

      &::before {
        height: 100%;
        width: 6px;
        background: linear-gradient(#fff500 0%, #ff8a00 100%);
      }
    }
    &.right {
      background: linear-gradient(#ff00e5 0%, #8f00ff 100%);
      height: 100%;
      width: 1px;
      bottom: 0;
      top: 0;
      right: 0;

      &::before {
        height: 100%;
        width: 1px;
        background: linear-gradient(#fff500 0%, #ff8a00 100%);
      }
    }
    &.bottom {
      background-color: #8f00ff;
      height: 1px;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;

      &::before {
        height: 1px;
        width: 100%;
        background-color: #ff8a00;
      }
    }
  }

  &._arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;

    svg {
      flex: 0 0 auto;
      width: 50px;
      height: 50px;
    }

    span {
      transition: all 0.4s ease;
      &.top {
        background-color: #ff00e5;
        height: 6px;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
      }
      &.left {
        background: linear-gradient(#ff00e5 0%, #8f00ff 100%);
        height: 100%;
        width: 1px;
        bottom: 0;
        top: 0;
        left: 0;
      }
      &.right {
        background: linear-gradient(#ff00e5 0%, #8f00ff 100%);
        height: 100%;
        width: 1px;
        bottom: 0;
        top: 0;
        right: 0;
      }
      &.bottom {
        background-color: #8f00ff;
        height: 1px;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    ._hover {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 0.4s ease;
      opacity: 0;
      background: linear-gradient(105.26deg, #ffc70033 0%, #ffffff00 100%);
      backdrop-filter: blur(15px);

      span {
        transition: all 0.4s ease;
        &::before {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          transition: all 0.4s ease;
          opacity: 0;
        }

        &.top {
          background-color: #fff500;
          width: 100%;
          height: 6px;
          top: 0;
          left: 0;
          right: 0;
        }
        &.left {
          background: linear-gradient(#fff500 0%, #ff8a00 100%);
          height: 100%;
          bottom: 0;
          top: 0;
          left: 0;
          width: 1px;
        }
        &.right {
          background: linear-gradient(#fff500 0%, #ff8a00 100%);
          height: 100%;
          width: 1px;
          bottom: 0;
          top: 0;
          right: 0;
        }
        &.bottom {
          background-color: #ff8a00;
          height: 1px;
          width: 100%;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }

  &:hover {
    span {
      opacity: 0;
    }

    ._hover {
      opacity: 1;

      span {
        opacity: 1;
      }
    }
  }

  &:focus {
    &::before {
      opacity: 0;
    }
  }
}
