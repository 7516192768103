.num {
  position: relative;
  width: 50px;
  height: 50px;

  span {
    position: absolute;
    display: block;

    &._num {
      top: 0;
      right: 0;
      font-weight: 500;
      font-size: 30px;
      line-height: 37px;
      text-transform: uppercase;
      color: #ffffff;
    }
    &._lastnum {
      bottom: 0;
      left: 0;
      bottom: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #ffffff;
    }
    &._left {
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border-left: 2px solid #fff;
      border-top: 2px solid #fff;
    }
    &._right {
      top: 0;
      right: 0;
      width: 10px;
      height: 2px;
      background-color: #fff;
    }
    &._bottom {
      bottom: 0;
      right: 0;
      bottom: 0;
      right: 0;
      width: 10px;
      height: 10px;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
    }
  }
}
