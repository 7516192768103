.header {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
      90.16deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  transition: all 0.5s ease;

  @media (max-width: 991px) {
    height: 60px;
    overflow: hidden;

    &._menuOpen {
      height: 100vh;
    }
  }

  &Body {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 991px) {
      height: 60px;
    }

    &__logo {
      flex: 0 0 auto;
      width: 50px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      @media (max-width: 991px) {
        width: 30px;
        height: 30px;
      }
    }

    &__link {
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #ffffff;
      transition: all 0.3s ease;

      &:hover {
        color: #ffc700;
      }

      &:focus {
        color: #00a3ff;
      }
    }
  }

  &Links {
    position: absolute;
    top: 0;
    padding: 65px 0;

    .headerBody__link {
      &:not(:first-child) {
        margin: 20px 0 0;
      }
    }
  }
}
