.videobg {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  span {
    height: 200px;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);

    &._bottom {
      bottom: 0;
      background: linear-gradient(180deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
    }

    &._top {
      top: 0;
      transform: scale(1, -1);
    }
  }
}

.sectionTop {
  display: flex;
  align-items: center;

  &__title {
    flex: 0 0 auto;
    position: relative;
    font-weight: 900;
    font-size: 30px;
    line-height: 37px;
    text-transform: uppercase;
    color: #ffffff;

    &Bg {
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: -98px;
      transform: translate(0, -50%);

      @media (max-width: 991px) {
        left: -24px;

        img {
          height: 73px;
          object-fit: contain;
        }
      }
    }
  }
  &__content {
    flex: 1 1 100%;
    position: relative;
    margin: 0 0 0 20px;

    &Line {
      position: absolute;
      z-index: 0;
      top: 50%;
      right: 0;
      left: 0;
      transform: translate(0, -50%);

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 8px;
        right: 0;
        height: 2px;
        background-color: #ffffff;
      }

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        display: block;
        background-image: url(./assets/img/icons/circle.svg);
      }
    }
  }
}

.main {
  position: relative;
  overflow: hidden;
  padding: 150px 0 100px;

  @media (max-width: 991px) {
    padding: 66px 0 30px;
    background-image: url(./assets/img/main/bg.jpg);
    background-repeat: no-repeat;
    background-position: 50% 2px;
    background-size: cover;

    &::after {
      content: '';
      bottom: 0;
      background: linear-gradient(180deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 126px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  &Parallax {
    overflow: visible !important;
    z-index: 1;
    transform: translate(100px, 0);

    &__item {
      pointer-events: none;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 145px;
      width: 445px;
      height: 557px;
      opacity: 0.6;

      .mainCard__parallaxItem {
        transform: translate(0, -50%);
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .mainCard__phone {
        transform: translate(0, -50%);
      }

      &:nth-child(3) {
        z-index: -2;
        left: 291px;
        width: 351px;
        height: 439px;
        opacity: 0.5;
      }
      &:nth-child(4) {
        z-index: -3;
        left: 420px;
        width: 264px;
        height: 331px;
        opacity: 0.4;
      }
      &:nth-child(5) {
        z-index: -4;
        left: 480px;
        width: 226px;
        height: 283px;
        opacity: 0.3;
      }
      &:nth-child(6) {
        z-index: -5;
        left: 412px;
        opacity: 1;
      }
    }
  }

  &Body {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 0 74px;

    @media (max-width: 991px) {
      z-index: 1;
      padding: 0;
      flex-direction: column;
      align-items: center;
      justify-content: unset;
      max-width: 450px;
      margin: 0 auto;
    }

    &__downBtn {
      position: absolute;
      top: 0px;
      right: -140px;
    }

    &__text {
      pointer-events: none;
      position: absolute;
      // top: 50%;
      bottom: 125px;
      right: 104px;
      // transform: translate(0, -50%);
    }
  }

  &Icons {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &__item {
      position: absolute;

      &:nth-child(1) {
        top: 0;
        left: 0;
      }
      &:nth-child(2) {
        top: 0;
        right: 0;
      }
      &:nth-child(3) {
        bottom: 0;
        left: 0;
      }
      &:nth-child(4) {
        bottom: 0;
        right: 0;
      }
    }
  }

  &Card {
    width: 540px;
    position: relative;
    z-index: 1;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 30px;

    @media (max-width: 991px) {
      padding: 15px;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
          119.77deg,
          rgba(255, 0, 229, 0.2) 31.81%,
          rgba(0, 163, 255, 0.2) 87.88%
        ),
        rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(10px);
    }

    &__img {
      position: relative;
      width: 480px;
      height: 438px;

      @media (max-width: 991px) {
        width: 100%;
        height: 0;
        padding: 0 0 91%;
      }

      &Border {
        position: absolute;
        right: 0;
        left: 0;

        img {
          width: 100%;
          height: 100%;
        }

        @media (max-width: 991px) {
          z-index: 1;
          bottom: 0;
          top: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;

        @media (max-width: 991px) {
          position: absolute;
          z-index: 0;
          bottom: 0;
          right: 0;
          top: 0;
          left: 0;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &__title {
      margin: 30px 0 0;
      font-weight: 900;
      font-size: 36px;
      line-height: 44px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #ffffff;

      @media (max-width: 991px) {
        margin: 18px 0 0;
        font-size: 19px;
        line-height: 24px;
        text-align: center;
        width: 100%;
        display: block;
      }
    }
    &__bottom {
      margin: 43px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 991px) {
        margin: 24px 0 0;
      }

      &Pos {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #ffffff;

        @media (max-width: 991px) {
          font-size: 12px;
          line-height: 15px;
        }
      }
      &Btn {
        @media (max-width: 991px) {
          height: 32px;
          font-size: 9px;
          line-height: 12px;
          padding: 0 22px;
        }
      }
    }

    @media (max-width: 991px) {
      margin: 26px 0 0;
      width: 100%;
    }
  }

  &Info {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 0 110px;

    &__title {
      display: flex;
      flex-wrap: wrap;

      text-align: right;
      font-style: italic;
      font-weight: 900;
      font-size: 90px;
      // line-height: 110px;
      text-transform: uppercase;
      color: #ffffff;

      @media (max-width: 1200px) {
        font-size: 60px;
      }

      @media (max-width: 991px) {
        max-width: 340px;
      }

      span {
        display: block;
        flex: 0 0 100%;

        &:nth-child(1) {
          padding: 0 120px 0 0;
          @media (max-width: 991px) {
            padding: 0 70px 0 0;
          }
        }
      }
    }
    &__btn {
      margin: 20px 0 0;

      @media (max-width: 991px) {
        width: calc(100% - 16px);
      }
    }
  }
}

.video {
  padding: 93px 0 114px;

  @media (max-width: 991px) {
    padding: 38px 0 55px;
  }

  &Body {
    position: relative;

    &__preview {
      position: relative;
      z-index: 1;
      padding: 0 0 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__btn {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.4s ease;

      &:hover {
        opacity: 0.8;
      }

      @media (max-width: 991px) {
        width: 24px;
        height: 24px;

        svg {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &__text {
      pointer-events: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      ._top {
        position: absolute;
        top: -170px;
        right: -110px;

        @media (max-width: 991px) {
          width: 210px;
          height: 74px;
          right: 4px;
          top: -36px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      ._bottom {
        position: absolute;
        left: -66px;
        bottom: -166px;

        @media (max-width: 991px) {
          width: 163px;
          height: 74px;
          left: 0;
          bottom: -38px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    &__num {
      position: absolute !important;
      right: 0;
      bottom: -90px;
    }
  }
}

.clients {
  position: relative;
  padding: 118px 0 100px;

  .sectionTop__titleBg {
    left: -123px;

    @media (max-width: 991px) {
      left: -24px;
    }
  }

  @media (max-width: 991px) {
    background-image: url(./assets/img/clients/bg.jpg);
    background-repeat: no-repeat;
    background-position: 50% 2px;
    background-size: cover;
    padding: 20px 0;

    &::after {
      content: '';
      bottom: 0;
      background: linear-gradient(180deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 126px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }
    &::before {
      content: '';
      top: 0;
      background: linear-gradient(0deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 126px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  &Top {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 991px) {
      flex-wrap: wrap;
      margin: 10px 0 0;
    }

    &__item {
      position: relative;
      flex: 0 1 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px;
      background: linear-gradient(
          119.77deg,
          rgba(255, 255, 255, 0.2) 31.81%,
          rgba(255, 255, 255, 0) 87.88%
        ),
        rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(15px);

      span {
        position: absolute;
        left: 0;
        right: 0;

        &::before,
        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 4px;
          height: 4px;

          @media (max-width: 991px) {
            width: 2px;
            height: 2px;
          }
        }

        &::before {
          left: 0;
        }
        &::after {
          right: 0;
        }
      }

      @media (max-width: 991px) {
        justify-content: flex-start;
        flex: 1 1 100%;
        flex-wrap: wrap;
        margin: 10px 0 0;
      }

      &:not(:first-child) {
        margin: 0 0 0 30px;
        @media (max-width: 991px) {
          margin: 8px 0 0;
        }
      }

      &Value {
        font-weight: 900;
        font-size: 80px;
        line-height: 98px;
        text-transform: uppercase;
        color: #ffffff;

        @media (max-width: 1440px) {
          font-size: 50px;
          line-height: 68px;
        }
        @media (max-width: 1200px) {
          font-size: 30px;
          line-height: 38px;
        }

        @media (max-width: 991px) {
          font-size: 24px;
          line-height: 29px;
        }
      }
      &Text {
        margin: 0 0 0 20px;
        font-weight: 900;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #ffffff;
        @media (max-width: 1440px) {
          font-size: 16px;
          line-height: 20px;
        }
        @media (max-width: 1440px) {
          font-size: 14px;
          line-height: 18px;
        }
        @media (max-width: 991px) {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }

  &Icons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    &__item {
      position: absolute !important;

      &:nth-child(1) {
        left: 0;
        bottom: 2px;
        transform: rotate(180deg);
      }
      &:nth-child(2) {
        left: 70px;
        bottom: 0;
      }
      &:nth-child(3) {
        right: 0;
        bottom: 0;
      }
    }
  }

  &Body {
    position: relative;
    padding: 224px 0 240px;
    display: flex;

    @media (max-width: 1440px) {
      padding: 140px 0;
    }

    @media (max-width: 991px) {
      flex-wrap: wrap;
      padding: 30px 0 10px;
    }

    &__item {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 1 240px;
      padding: 0 0 15.095%;
      background: linear-gradient(
          136.68deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0) 103.11%
        ),
        rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(15px);
      transition: all 0.4s ease;

      &:not(:first-child) {
        margin: 0 0 0 30px;
      }

      img {
        position: relative;
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        background: linear-gradient(
            135deg,
            rgba(0, 163, 255, 0.5) 0%,
            rgba(255, 255, 255, 0) 100%
          ),
          rgba(0, 0, 0, 0.5);
        transition: all 0.4s ease;
        opacity: 0;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }

      span {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &::after,
        &::before {
          content: '';
          position: absolute;
          display: block;
          width: 2px;
          height: 2px;
          background-color: #ffffff;
        }

        &:nth-child(1) {
          &::after {
            top: 0;
            left: 0;
          }
          &::before {
            right: 0;
            top: 0;
          }
        }
        &:nth-child(2) {
          &::after {
            bottom: 0;
            left: 0;
          }
          &::before {
            right: 0;
            bottom: 0;
          }
        }
      }

      @media (max-width: 991px) {
        flex: 0 0 calc(50% - 15px);
        padding: 0 0 calc(50% - 15px);

        &:not(:first-child) {
          margin: 30px 0 0;
        }
        &:nth-child(even) {
          margin: 30px 0 0 30px;
        }

        &:nth-child(1) {
          margin: 0;
        }
        &:nth-child(2) {
          margin: 0 0 0 30px;
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: none;
        object-position: center;
      }

      @media (max-width: 1500px) {
        img {
          bottom: auto;
          right: auto;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 70%;
          height: 70%;
          object-fit: contain;
        }
      }

      @media (max-width: 991px) {
        img {
          width: 64%;
          height: 64%;
        }
        &:nth-child(1) {
          img {
            width: 50%;
            height: 50%;
          }
        }
        &:nth-child(2) {
          img {
            width: 80%;
            height: 80%;
          }
        }
        &:nth-child(3) {
          img {
            width: 70%;
            height: 70%;
          }
        }
        &:nth-child(4) {
          img {
            width: 60%;
            height: 60%;
          }
        }
        &:nth-child(5) {
          img {
            width: 72%;
            height: 72%;
          }
        }
      }
    }
  }
}

.brands {
  position: relative;
  padding: 182px 0 50px;

  @media (max-width: 991px) {
    padding: 16px 0 35px;
  }

  @media (max-width: 991px) {
    background-image: url(./assets/img/brands/bg.png);
    background-repeat: no-repeat;
    background-position: 50% 2px;
    background-size: cover;

    &::after {
      content: '';
      bottom: 0;
      background: linear-gradient(180deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 200px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }
    &::before {
      content: '';
      top: 0;
      background: linear-gradient(0deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 200px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  &Top {
    position: relative;
    z-index: 1;
    &__text {
      margin: 0 0 0 auto;
      max-width: 520px;
      position: relative;
      z-index: 1;
      background: linear-gradient(
          96.31deg,
          rgba(255, 255, 255, 0.2) 45.02%,
          rgba(255, 255, 255, 0) 65.62%
        ),
        rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(15px);
      padding: 20px;
      font-family: 'Open Sans';
      font-size: 14px;
      line-height: 19px;
      color: #ffffff;

      @media (max-width: 991px) {
        max-width: 100%;
        margin: 30px 0 0;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  &Body {
    position: relative;
    z-index: 1;
    display: flex;
    padding: 190px 0 252px;

    @media (max-width: 991px) {
      padding: 30px 0 0;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    &__item {
      cursor: pointer;
      flex: 0 1 375px;
      padding: 0 0 23.585%;
      position: relative;
      background: linear-gradient(
          135deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(15px);
      transition: all 0.4s ease;

      img {
        position: relative;
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        background: linear-gradient(
            135deg,
            rgba(0, 163, 255, 0.5) 0%,
            rgba(255, 255, 255, 0) 100%
          ),
          rgba(0, 0, 0, 0.5);
        transition: all 0.4s ease;
        opacity: 0;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }
      &:not(:first-child) {
        margin: 0 0 0 30px;
      }

      span {
        position: absolute;
        left: 0;
        right: 0;

        &::before,
        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 4px;
          height: 4px;
          background-color: #ffffff;

          @media (max-width: 991px) {
            width: 2px;
            height: 2px;
          }
        }

        &::before {
          left: 0;
        }
        &::after {
          right: 0;
        }

        &:nth-child(1) {
          top: 0;

          &::before,
          &::after {
            top: 0;
          }
        }
        &:nth-child(2) {
          bottom: 0;

          &::before,
          &::after {
            bottom: 0;
          }
        }
      }

      @media (max-width: 767px) {
        flex: 0 0 calc(50% - 15px);
        padding: 0 0 calc(50% - 15px);

        &:not(:first-child) {
          margin: 30px 0 0;
        }
        &:nth-child(even) {
          margin: 30px 0 0 30px;
        }

        &:nth-child(1) {
          margin: 0;
        }
        &:nth-child(2) {
          margin: 0 0 0 30px;
        }
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: 1400px) {
          width: calc(100% - 90px);
          height: calc(100% - 90px);
        }
        @media (max-width: 767px) {
          width: calc(100% - 60px);
          height: calc(100% - 60px);
        }
      }
    }
  }
}

.workflow {
  position: relative;
  padding: 130px 0 0;

  .sectionTop__titleBg {
    top: -50px;
    left: -72px;
    transform: translate(0);

    @media (max-width: 991px) {
      left: -24px;
    }
  }

  @media (max-width: 991px) {
    padding: 45px 0 56px;
    background-image: url(./assets/img/workflow/bg.jpg);
    background-repeat: no-repeat;
    background-position: 50% 2px;
    background-size: cover;

    .sectionTop__titleBg {
      top: -14px;
      transform: translate(0);
      img {
        height: 102px;
      }
    }

    &::before {
      content: '';
      top: 0;
      background: linear-gradient(0deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 126px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }
    &::after {
      content: '';
      bottom: 0;
      background: linear-gradient(180deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 126px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  &Body {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding: 786px 10px 100px 0;

    @media (max-width: 991px) {
      margin: 30px 0 0;
      padding: 0;
      flex-direction: column;
      align-items: center;
      justify-content: unset;

      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        width: 2px;
        top: 70px;
        bottom: 0;
        left: 50%;
        background-color: rgba($color: #fff, $alpha: 0.6);
        transform: translate(-50%, 0);
      }
    }

    &__line {
      position: absolute;
      bottom: 400px;
      left: 30px;
      right: 70px;

      img {
        width: 100%;
      }
    }

    &__item {
      position: relative;
      z-index: 1;

      @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:not(:first-child) {
          margin: 63px 0 0 !important;
        }
      }

      &Title {
        @media (max-width: 991px) {
          font-weight: 900;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
        }
      }
      &Icon {
        @media (max-width: 991px) {
          margin: 34px 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0.2) 0%,
              rgba(255, 255, 255, 0) 100%
            ),
            rgba(0, 0, 0, 0.5);
          border: 1px solid rgba(255, 255, 255, 0.2);
          backdrop-filter: blur(15px);
          border-radius: 50%;
          width: 72px;
          height: 72px;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            display: block;
            width: 100px;
            height: 100px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            border: 1px solid #00a3ff;
          }
        }
      }

      .name {
        font-weight: 900;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
      }

      .line {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: #fff;
        width: 2px;
        height: 492px;

        &Icon {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);

          background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0.2) 0%,
              rgba(255, 255, 255, 0) 100%
            ),
            rgba(0, 0, 0, 0.5);
          border: 1px solid rgba(255, 255, 255, 0.2);
          backdrop-filter: blur(15px);
          border-radius: 50%;
          width: 72px;
          height: 72px;
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            content: '';
            display: block;
            width: 100px;
            height: 100px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            border: 1px solid #00a3ff;
          }

          img {
          }

          .radial {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &Item {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              border: 1px solid #7c7c7c;
              border-radius: 50%;

              &:nth-child(1) {
                z-index: 3;
                width: 200px;
                height: 200px;
              }
              &:nth-child(2) {
                z-index: 2;
                width: 300px;
                height: 300px;
              }
              &:nth-child(3) {
                z-index: 1;
                width: 400px;
                height: 400px;
              }

              &._hidden {
                display: none;
              }

              &__tooltip {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);

                &:nth-child(2) {
                  top: auto;
                  bottom: 0;
                }

                &._bottom {
                  top: auto;
                  bottom: 0;
                }

                span {
                  cursor: pointer;
                  width: 30px;
                  height: 30px;
                  position: absolute;
                  z-index: 1;
                  top: 0;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  border: 1px solid rgba(255, 255, 255, 0.2);
                  border-radius: 50%;
                  background: linear-gradient(180deg, #323b40 0%, #041016 100%);

                  &::after {
                    content: '';
                    display: block;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: #00a3ff;
                    transition: all 0.3s ease;
                  }
                }

                &Content {
                  pointer-events: none;
                  position: absolute;
                  z-index: -1;
                  left: 30px;
                  // left: calc(50% + 30px);
                  top: 50%;
                  transform: translate(-20px, -50%);
                  padding: 10px;
                  white-space: nowrap;

                  opacity: 0;
                  transition: all 0.4s ease;

                  background: linear-gradient(
                      96.31deg,
                      rgba(255, 255, 255, 0.2) 45.02%,
                      rgba(255, 255, 255, 0) 65.62%
                    ),
                    rgba(0, 0, 0, 0.5);
                  border: 1px solid rgba(255, 255, 255, 0.2);
                  backdrop-filter: blur(15px);

                  font-family: 'Open Sans';
                  font-size: 14px;
                  line-height: 19px;
                  color: #ffffff;

                  &::before {
                    content: '';
                    display: block;
                    width: 30px;
                    height: 2px;
                    position: absolute;
                    top: 50%;
                    right: 100%;
                    transform: translate(0, -50%);
                    background-color: #fff;
                  }
                }
                &:hover {
                  .radialItem__tooltipContent {
                    opacity: 1;
                    transform: translate(0, -50%);
                  }

                  span {
                    &::after {
                      background-color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
        &Circle {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 0);
          background: #0e1726;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          border: 2px solid #ffffff;

          &::after {
            content: '';
            position: absolute;
            display: block;
            background: #fff;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &:nth-child(2) {
        margin: 0 0 0 50px;
        .line {
          height: 306px;
        }
      }
      &:nth-child(3) {
        margin: 0 20px 0 0;
      }
      &:nth-child(4) {
        margin: 0 46px 0 0;
        .line {
          height: 680px;
        }
      }

      @media (max-width: 1560px) {
        &:nth-child(1) {
          .line {
            height: 30vw;
          }
        }
        &:nth-child(2) {
          .line {
            height: 20vw;
          }
        }
        &:nth-child(3) {
          .line {
            height: 31vw;
          }
        }
        &:nth-child(4) {
          .line {
            height: 43vw;
          }
        }
        &:nth-child(5) {
          .line {
            height: 31vw;
          }
        }
      }
      @media (max-width: 1480px) {
        &:nth-child(1) {
          .line {
            height: 32vw;
          }
        }
        &:nth-child(2) {
          .line {
            height: 22vw;
          }
        }
        &:nth-child(3) {
          .line {
            height: 33vw;
          }
        }
        &:nth-child(4) {
          .line {
            height: 44vw;
          }
        }
        &:nth-child(5) {
          .line {
            height: 33vw;
          }
        }
      }
      @media (max-width: 1380px) {
        &:nth-child(1) {
          .line {
            height: 35vw;
          }
        }
        &:nth-child(2) {
          .line {
            height: 25vw;
          }
        }
        &:nth-child(3) {
          .line {
            height: 36vw;
          }
        }
        &:nth-child(4) {
          .line {
            height: 47vw;
          }
        }
        &:nth-child(5) {
          .line {
            height: 38vw;
          }
        }
      }
      @media (max-width: 1180px) {
        &:nth-child(1) {
          .line {
            height: 39vw;
          }
        }
        &:nth-child(2) {
          .line {
            height: 29vw;
          }
        }
        &:nth-child(3) {
          .line {
            height: 39vw;
          }
        }
        &:nth-child(4) {
          .line {
            height: 50vw;
          }
        }
        &:nth-child(5) {
          .line {
            height: 39vw;
          }
        }
      }
    }
  }

  &Icons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &__item {
      position: absolute !important;

      &:nth-child(1) {
        top: -72px;
        right: 0;
      }
      &:nth-child(2) {
        right: 0;
        bottom: 0;
      }
    }
  }
}

.advantages {
  padding: 134px 0 53px;
  position: relative;
  .videobg {
    ._top {
      height: 430px;
    }
  }

  @media (max-width: 991px) {
    background-image: url(./assets/img/advantages/bg.jpg);
    background-repeat: no-repeat;
    background-position: 50% 2px;
    background-size: cover;
    padding: 64px 0 20px;

    &::after {
      content: '';
      bottom: 0;
      background: linear-gradient(180deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 510px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }
    &::before {
      content: '';
      top: 0;
      background: linear-gradient(0deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 810px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }

    .sectionTop__titleBg {
      top: 65%;
      left: -42px;

      img {
        height: 55px;
      }
    }
  }

  &Body {
    position: relative;
    z-index: 1;
    padding: 183px 0 185px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
      margin: 30px 0 0;
      flex-wrap: wrap;
      padding: 0;
    }

    &__item {
      position: relative;
      padding: 20px 20px 40px;
      flex: 0 0 calc(25% - 22.5px);
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        rgba(0, 0, 0, 0.5);
      // border: 1px solid rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(15px);

      @media (max-width: 991px) {
        flex: 1 1 100%;

        &:not(:first-child) {
          margin: 10px 0 0;
        }
      }

      &::after {
        pointer-events: none;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        border: 1px solid rgba(255, 255, 255, 0.2);
      }

      &Ico {
        width: 80px;
        height: 80px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &Title {
        margin: 30px 0 0;
        font-weight: 900;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #ffffff;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

        @media (max-width: 991px) {
          margin: 30px 0 0;
          font-size: 16px;
          line-height: 20px;
        }
      }
      &Desc {
        margin: 10px 0 0;
        font-family: 'Open Sans';
        font-size: 14px;
        line-height: 19px;
        color: #ffffff;

        p {
          &:not(:first-child) {
            margin: 20px 0 0;
          }
        }

        @media (max-width: 991px) {
          font-size: 12px;
          line-height: 16px;
          p {
            &:not(:first-child) {
              margin: 16px 0 0;
            }
          }
        }
      }
    }

    &__icons {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &Item {
        position: absolute !important;

        &:nth-child(1) {
          top: -74px;
          right: 0;
        }
        &:nth-child(2) {
          bottom: 0;
          left: 0;
        }
        &:nth-child(3) {
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}

.callus {
  position: relative;
  padding: 134px 0 0 0;

  @media (max-width: 991px) {
    background-image: url(./assets/img/advantages/bg.jpg);
    background-repeat: no-repeat;
    background-position: 50% 2px;
    background-size: cover;
    padding: 38px 0 35px;

    &::after {
      content: '';
      bottom: 0;
      background: linear-gradient(180deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 510px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }
    &::before {
      content: '';
      top: 0;
      background: linear-gradient(0deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 810px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }

    .sectionTop__titleBg {
      top: 50%;
      left: -26px;

      img {
        height: 102px;
      }
    }
  }

  &Body {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    padding: 200px 0 220px;

    @media (max-width: 991px) {
      margin: 30px 0 0;
      padding: 0;
    }

    .form {
      position: relative;
      flex: 0 0 645px;
      display: flex;
      flex-direction: column;
      background: linear-gradient(
          64.59deg,
          rgba(255, 255, 255, 0.2) 32.21%,
          rgba(255, 255, 255, 0) 68.69%
        ),
        rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(15px);
      padding: 20px;

      @media (max-width: 991px) {
        flex: 1 1 auto;
      }

      ._err {
        margin: 10px 0 0;
        color: rgb(255, 77, 77);
        @media (max-width: 991px) {
          margin: 4px 0 0;
          font-size: 12px;
        }
      }

      &__item {
        width: 100%;
        padding: 14px 20px;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.2);

        &._textarea {
          resize: none;
          height: 100px;
          padding: 8px 20px;

          @media (max-width: 991px) {
            height: 80px;
            padding: 12px 20px;
          }
        }

        @media (max-width: 991px) {
          font-size: 12px;
          line-height: 15px;
        }

        &::placeholder {
          color: #ffffff;
        }

        &:not(:first-child) {
          margin: 20px 0 0;
          @media (max-width: 991px) {
            margin: 8px 0 0;
          }
        }
      }
      &__btn {
        align-self: flex-start;

        @media (max-width: 991px) {
          align-self: center;
          margin: 20px 0 0;
        }
      }

      &Success {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(
            112deg,
            rgba(0, 163, 255, 0.5) 0%,
            rgba(0, 163, 255, 0) 100%
          ),
          rgba(0, 0, 0, 0.5);
        border: 1px solid rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(15px);
        padding: 0 5px;
        transition: all 0.5s ease;

        pointer-events: none;
        opacity: 0;

        &.active {
          pointer-events: unset;
          opacity: 1;
        }

        &__logo {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 200px;
          height: 200px;

          @media (max-width: 991px) {
            width: 140px;
            height: 140px;

            img {
              width: 70px;
              object-fit: contain;
            }
          }

          span {
            position: absolute;
            height: 4px;
            left: 0;
            right: 0;

            &:nth-child(1) {
              top: 0;
            }
            &:nth-child(2) {
              bottom: 0;
            }

            &::before,
            &::after {
              content: '';
              position: absolute;
              display: block;
              width: 4px;
              height: 4px;
              background-color: #fff;
            }

            &::before {
              left: 0;
            }
            &::after {
              right: 0;
            }
          }
        }
        &__text {
          margin: 60px 0 0;
          font-weight: 900;
          font-size: 24px;
          line-height: 29px;
          text-transform: uppercase;
          color: #ffffff;
          text-align: center;

          @media (max-width: 991px) {
            margin: 30px 0 0;
            font-size: 20px;
            line-height: 25px;
          }
        }
      }

      &Bottom {
        margin: 20px 0 0;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
          flex-direction: column-reverse;
          margin: 10px 0 0;
        }

        &__text {
          margin: 0 0 0 20px;

          font-family: 'Open Sans';
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;

          @media (max-width: 991px) {
            text-align: center;
            font-size: 10px;
            line-height: 14px;
            margin: 0;
          }

          a {
            color: #ffffff;
            text-decoration: underline;
          }
        }
      }
    }

    &__icons {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      &Item {
        position: absolute !important;
        bottom: 0;
        &:nth-child(1) {
          left: 0;
          svg {
            transform: rotate(180deg);
          }
        }
        &:nth-child(2) {
          right: 0;
        }
      }
    }
  }
}

.media {
  position: relative;
  padding: 154px 0 97px;

  .sectionTop__titleBg {
    top: 78%;
  }

  @media (max-width: 991px) {
    background-image: url(./assets/img/media/bg.png);
    background-repeat: no-repeat;
    background-position: 50% bottom;
    background-size: 100%;
    padding: 16px 0 35px;

    &::after {
      content: '';
      bottom: 0;
      background: linear-gradient(180deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 200px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }
    &::before {
      content: '';
      top: 0;
      background: linear-gradient(0deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 200px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }

    .sectionTop__titleBg {
      top: 60%;

      img {
        height: 54px;
      }
    }
  }

  &Body {
    position: relative;
    z-index: 1;
    padding: 40px 0 100px;
    @media (max-width: 991px) {
      padding: 30px 0 0;
    }

    &__row {
      display: flex;
      justify-content: space-between;

      @media (max-width: 991px) {
        flex-wrap: wrap;
      }
    }

    &__item {
      position: relative;
      flex: 0 0 calc(33.333% - 20px);
      background: #d9d9d9;
      padding: 0 0 18%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }

      @media (max-width: 991px) {
        flex: 1 1 100%;
        padding: 0 0 56%;

        &:not(:first-child) {
          margin: 10px 0 0;
        }
      }
    }
  }

  &Icons {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &__item {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &Articles {
    margin: 40px 0 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-wrap: wrap;
      margin: 30px 0 0;
    }

    &__title {
      margin: 100px 0 0;
      font-weight: 900;
      font-size: 30px;
      line-height: 37px;
      text-transform: uppercase;
      color: #ffffff;

      @media (max-width: 991px) {
        margin: 56px 0 0;
      }
    }

    &__item {
      flex: 0 0 calc(33.333% - 20px);
      background: linear-gradient(
          133.32deg,
          rgba(255, 255, 255, 0.2) 46.32%,
          rgba(255, 255, 255, 0) 85.27%
        ),
        rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(15px);

      @media (max-width: 991px) {
        flex: 1 1 100%;

        &:not(:first-child) {
          margin: 10px 0 0;
        }
      }

      &Img {
        position: relative;
        background: #d9d9d9;
        padding: 0 0 56.5%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      &Desc {
        padding: 20px;

        .title {
          font-weight: 900;
          font-size: 18px;
          line-height: 22px;
          text-transform: uppercase;
          color: #ffffff;

          @media (max-width: 991px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
        .text {
          margin: 15px 0 0;
          font-family: 'Open Sans';
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;

          // display: -webkit-box;
          // -webkit-line-clamp: 5;
          // -webkit-box-orient: vertical;
          // overflow: hidden;

          @media (max-width: 991px) {
            // -webkit-line-clamp: 6;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.contacts {
  position: relative;
  padding: 62px 0 372px;

  @media (max-width: 991px) {
    background-image: url(./assets/img/contacts/bg.jpg);
    background-repeat: no-repeat;
    background-position: 50% 2px;
    background-size: cover;
    padding: 18px 0 50px;

    .sectionTop {
      position: relative;
      z-index: 1;
    }

    &::after {
      content: '';
      top: 0;
      background: linear-gradient(0deg, rgba(14, 23, 38, 0) 0%, #0e1726 100%);
      height: 200px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }

    .sectionTop__titleBg {
      top: 50%;

      img {
        height: 54px;
      }
    }
  }

  .sectionTop__content {
    display: flex;
  }
  &Body {
    position: relative;
    z-index: 1;
    padding: 20px;
    margin: 0 0 0 auto;
    display: inline-flex;
    align-items: center;
    background: linear-gradient(
        100.65deg,
        rgba(255, 255, 255, 0.2) 42.08%,
        rgba(255, 255, 255, 0) 74.01%
      ),
      rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);

    @media (max-width: 991px) {
      flex-wrap: wrap;
      margin: 30px 0 0;
    }

    &__btn {
      @media (max-width: 991px) {
        flex: 1 1 100%;
        padding: 0 20px;

        &:not(:first-child) {
          margin: 20px 0 0;
        }
      }
    }
    &__socials {
      display: flex;

      @media (max-width: 991px) {
        margin: 20px 0 0;
        flex: 1 1 100%;
        justify-content: space-between;
      }

      &Item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        margin: 0 0 0 20px;

        transition: all 0.4s ease;

        img {
          position: relative;
          z-index: 1;
        }

        &::after {
          content: '';
          position: absolute;
          z-index: 0;
          display: block;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          background: linear-gradient(
            135deg,
            rgba(0, 163, 255, 0.5) 0%,
            rgba(0, 163, 255, 0) 100%
          );
          transition: all 0.4s ease;
        }

        &:hover {
          &::after {
            opacity: 1;
          }
        }

        @media (max-width: 1200px) {
          width: 50px;
          height: 50px;

          img {
            width: 70%;
            object-fit: contain;
          }
        }

        @media (max-width: 991px) {
          width: auto;
          height: auto;
          flex: 0 0 calc(50% - 10px);
          // padding: 0 0 47.34%;
          padding: 0 0 calc(50% - 10px);
          margin: 0;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 76%;
          }
        }

        span {
          pointer-events: none;
          position: absolute;
          left: 0;
          right: 0;
          height: 4px;

          &::before,
          &::after {
            content: '';
            position: absolute;
            display: block;
            height: 4px;
            width: 4px;
            background-color: #fff;

            @media (max-width: 991px) {
              width: 6px;
              height: 6px;
            }
          }

          &::before {
            left: 0;
          }
          &::after {
            right: 0;
          }

          &:nth-child(1) {
            top: 0;
          }
          &:nth-child(2) {
            bottom: 0;
          }
        }
      }
      &Mail {
        margin: 0 0 0 20px;
        font-weight: 900;
        font-size: 30px;
        line-height: 37px;
        text-transform: uppercase;
        color: #ffffff;
        transition: all 0.4s ease;

        @media (max-width: 1200px) {
          font-size: 24px;
          line-height: 30px;
        }
        @media (max-width: 991px) {
          margin: 20px 0 0;
          font-size: 18px;
          line-height: 22px;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  &Icons {
    display: flex;
    margin: 100px 0 0;

    &__item {
      &:nth-child(2) {
        margin: 0 0 0 20px;
      }
      &:nth-child(3) {
        margin: 0 0 0 auto;
      }
    }
  }
}
