@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('../assets/fonts/Montserrat-Black.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('../assets/fonts/Montserrat-BlackItalic.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-BlackItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('../assets/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('../assets/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('../assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('../assets/fonts/OpenSans-Regular.woff2') format('woff2'),
    url('../assets/fonts/OpenSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
